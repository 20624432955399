import axios from "axios";

const axo = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ADMIN_URL + "api",
  // headers: {
  //   "Content-Type": "multipart/form-data",
  // },
});

export default axo;
