import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "../main";

export interface OrderSummaryStates {
  step: number;
  isStripe: boolean;
}

const initialState: OrderSummaryStates = {
  step: 0,
  isStripe: true,
};

export const orderSummarySlice = createSlice({
  name: "order-summary",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      state.step += 1;
    },
    decrement: (state) => {
      state.step -= 1;
    },
    reset: (state) => {
      state.step = 0;
    },
    setIsStripe: (state, action: PayloadAction<boolean>) => {
      state.isStripe = action.payload;
    },
  },
});

export const { increment, decrement, reset, setIsStripe } =
  orderSummarySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStep = (state: AppState) => state.orderSummary.step;

export default orderSummarySlice.reducer;
