import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "../main";

export interface FormState {
  userName: string;
}

const initialState: FormState = {
  userName: "",
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { setUserName } = formSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserName = (state: AppState) => state.form.userName;

export default formSlice.reducer;
